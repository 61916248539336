/**
 * Accommodation swiper wrapper class
 */
export class AccommodationsComponent {

    constructor() {
        this.swiperClass = undefined;
        this.container = undefined;
        this.init();
    }

    init() {
        this.initSwiper();
    }

    /**
     * Initiate the swiper
     */
    initSwiper() {
        const swiperInstance = new Swiper(this.getSwiperClass(), {
            modules: [SwiperNavigation],
            navigation: {
                nextEl: `${this.getSwiperClass()}-next`,
                prevEl: `${this.getSwiperClass()}-prev`,
            },
            slidesPerView: 'auto',
            spaceBetween: 15,
            breakpoints: {
                0: {
                    slidesPerView: 2.4,
                    spaceBetween: 15,
                },
                '@0.75': {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                '@1.00': {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                '@1.20': {
                    slidesPerView: 6,
                    spaceBetween: 20,
                },
            },
            init: false,
        });

        swiperInstance.on('init', () => {
            this.getContainer().find(`${this.getSwiperClass()} img`).show();
        });

        swiperInstance.init();
    }

    /**
     * Returns the class of the swiper
     * @return {*}
     */
    getSwiperClass() {
        if (typeof this.swiperClass === 'undefined') {
            this.swiperClass = '.home-accommodations-swiper';
        }
        return this.swiperClass;
    }

    /**
     * Returns the container
     * @return {*}
     */
    getContainer() {
        if (typeof this.container === 'undefined') {
            this.container = $('#content-wrapper');
        }

        if (this.container.length === 0) {
            throw { element: this.container, reason: 'container not found!' };
        }
        return this.container;
    }

}
